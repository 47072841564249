body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'NotoSerif' 'RobotoMedium', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  @font-face {
    font-family: 'NotoSerif';
    src: local('NotoSerif'), url(../assets/fonts/NotoSerif-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(../assets/fonts/Roboto-Bold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'RobotoMedium';
    src: local('RobotoMedium'), url(../assets/fonts/Roboto-Medium.ttf) format('truetype');
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace , 'RobotoMedium', 'Roboto', 'NotoSerif';
  }